import styles from './header.module.scss';

import { MdNotificationsNone } from "react-icons/md";
import { IoLanguage } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa";
import { IoSettingsOutline } from "react-icons/io5";
import { IoMdHelpCircleOutline } from "react-icons/io";
import {useState} from "react";
import {useUser} from "../../utils/UserProvider";
import {useNavigate} from "react-router-dom";
import {useGlobalLoading} from "../../utils/LoadingProvider";

export const Header = () => {
    const [isListOpened, setIsListOpened] = useState(false);

    const navigate = useNavigate();
    const {logout} = useUser();
    const {startGlobalLoading, stopGlobalLoading} = useGlobalLoading();

    const navigateTo = (path: string) => {
        startGlobalLoading();
        setIsListOpened(false);
        navigate(path);
        stopGlobalLoading();
    }

    return (
        <header className={styles.header}>
            <div className={styles.header_logo}>
                <h1>SelfLead</h1>
            </div>

            <div className={styles.header_profile}>
                <button className={styles.header_profile_button}><MdNotificationsNone/></button>
                <button className={styles.header_profile_button}><IoLanguage/></button>

                <button className={styles.header_profile_button + " " + styles.avatar} onClick={() => setIsListOpened(!isListOpened)}><img src="https://i.pinimg.com/564x/2b/a2/45/2ba2455ca817f7659e9ebfe9d494c5db.jpg"/></button>

                <ul className={`${styles.header_profile_list} ${isListOpened ? styles.opened : ""}`}>
                    <li className={styles.header_profile_list_item} onClick={() => navigateTo("/profile")}><FaRegUser />Profil</li>
                    <li className={styles.header_profile_list_item}><IoSettingsOutline/>Nastavení</li>
                    <li className={styles.header_profile_list_item}><IoMdHelpCircleOutline/>Pomoc</li>

                    <button className={styles.header_profile_list_logout} onClick={() => logout()}>Odhlásit se</button>
                </ul>
            </div>
        </header>
    )
}