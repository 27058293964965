import {useEffect, useState} from "react";
import {api} from "../../../api/API";
import {useNavigate} from "react-router-dom";
import * as messages from "../../../messages";
import Cookies from "universal-cookie";

import styles from './loginpage.module.scss';

const LoginPage = () => {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [message, setMessage] = useState<string>("");

    const [loading, setLoading] = useState<boolean>(false);

    const navigate = useNavigate();
    const cookies : Cookies = new Cookies();

    const handleLogin = async () => {
        setLoading(true);
        if(email.trim() === "" || password.trim() === "") {
            setMessage("Je potřeba vyplnit všechny údaje");
            setLoading(false);
            return;
        }

        try {
            const response = await api.post('/login', { email, password });

            if(response != null && response.data.access_token != null && response.data.refresh_token != null &&  response.data.success) {
                cookies.set('accessToken', response.data.access_token as string, { path: '/', secure: true, sameSite: 'strict', expires: new Date(Date.now() + 60 * 60 * 1000)  });
                cookies.set('refreshToken', response.data.refresh_token as string, { path: '/', secure: true, sameSite: 'strict', expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)});

                navigate("/dashboard", { replace: true });

                setMessage(messages.LOGIN_SUCCESS);
            } else {
                setMessage("Přihlášení nebylo úspěšné.");
            }
            setLoading(false);

        } catch (e : any) {
            if (e.response && e.response.status === 401) {
                setMessage("Chybně zadané údaje.");
            } else {
                setMessage("Došlo k chybě. Zkuste to prosím znovu.");
            }
            setLoading(false);
        }
    }

    useEffect(() => {
        setLoading(true);
        if(cookies.get("accessToken") != null) {
            navigate("/dashboard", { replace: true });
        }
        setLoading(false);

        return () => {
            setLoading(false);
        }
    }, [])


    return (
        <div className={styles.login}>
            <div className={styles.login_block}>
                <div className={styles.login_block_logo}></div>
                <div className={styles.login_block_title}>
                    <h1 className={styles.login_block_title_main}>Příhlášení</h1>
                    <p className={styles.login_block_title_welcome}>Vítejte zpět!</p>
                </div>
                <div className={styles.login_block_form}>
                    <input className={styles.login_block_form_input} type="email" name="email" id="email" placeholder="Zadejte svůj email..." onChange={(e) => setEmail(e.target.value)}/>
                    <input className={styles.login_block_form_input} type="password" name="password" placeholder="Zadejte své heslo..." id="password" onChange={(e) => setPassword(e.target.value)}/>

                    <div className={styles.login_block_form_sett}>
                        <div className={styles.login_block_form_sett_sec}>
                            <input type="checkbox" name="remember" id="remember"/>
                            <label htmlFor="remember">Zapamatovat</label>
                        </div>

                        <div className={styles.login_block_form_sett_sec}>
                            <a href="#" className={styles.login_block_form_sett_sec_forgot}>Zapomenuté heslo?</a>
                        </div>
                    </div>
                    <button className={styles.login_block_form_submit} type="submit" onClick={handleLogin} disabled={loading}>{loading ? "Přihlašování..." : "Přihlásit se"}</button>
                    {message && <p>{message}</p>}
                </div>
            </div>
        </div>
    )
}

export default LoginPage