import React, {useEffect} from 'react';
import {BrowserRouter, Router, Routes} from "react-router-dom";
import {Route} from "react-router-dom";
import LoginPage from "./pages/main/login/LoginPage";
import {DashboardPage} from "./pages/dashboard/dashboard/DashboardPage";
import {ChatPage} from "./pages/dashboard/chat/ChatPage";
import DashboardLayout from "./layouts/dashboard_layout/DashboardLayout";
import {ProfilePage} from "./pages/dashboard/profile/ProfilePage";
import PrivateRoutes from "./utils/PrivateRoutes";
import {UserProvider} from "./utils/UserProvider";
import {LoadingProvider} from "./utils/LoadingProvider";


function App() {

  return (
    <BrowserRouter>
        <LoadingProvider>
            <UserProvider>
                <Routes>
                    <Route index path="/" element={<LoginPage/>}/>

                    {/* PrivateRoutes */}
                    <Route element={<PrivateRoutes/>}>
                        <Route path="/" element={<DashboardLayout/>}>
                            <Route index path="/dashboard" element={<DashboardPage/>}/>
                            <Route path="/chat" element={<ChatPage/>}/>
                            <Route path="/profile" element={<ProfilePage/>}/>
                        </Route>
                    </Route>
                    {/*...........*/}
                </Routes>
            </UserProvider>
        </LoadingProvider>
    </BrowserRouter>
  );
}

export default App;
