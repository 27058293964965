import * as React from 'react';
import {api} from "../api/API";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

import {useGlobalLoading} from "./LoadingProvider";
import {UserType} from "../types/UserType";
import {createContext, useContext, useState} from "react";


interface UserContextType {
    user: UserType | null;
    setUserData: (userData: UserType) => void;
    logout: () => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);
const cookies = new Cookies();

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<UserType | null>(null);
    const navigate = useNavigate();
    const { startGlobalLoading, stopGlobalLoading } = useGlobalLoading();

    const setUserData = (userData: UserType) => {
        setUser(userData);
    };


    const logout = () => {
        startGlobalLoading();

        cookies.remove('accessToken');
        cookies.remove('refreshToken');

        setUser(null);
        delete api.defaults.headers.common['Authorization'];
        navigate('/', { replace: true });

        stopGlobalLoading();
    };

    const value : UserContextType = { user, setUserData, logout };

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = (): UserContextType => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};
