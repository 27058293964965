import React, { useState, useEffect } from 'react';
import styles from './navigation.module.scss';
import { FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";
import { IoChatbubblesOutline } from "react-icons/io5";
import { MdFeedback } from "react-icons/md";
import {FeedBack} from "../feedback/FeedBack";
import { AiOutlineHome } from "react-icons/ai";
import { IoIosLogOut } from "react-icons/io";
import { VscFeedback } from "react-icons/vsc";
import {Modal} from "../modal/Modal";
import {useUser} from "../../utils/UserProvider";

const navigationList = [
    {
        icon: <AiOutlineHome />,
        title: 'Nástěnka',
        path: 'dashboard'
    },
    {
        icon: <IoChatbubblesOutline />,
        title: 'Chat',
        path: 'chat'
    },
];

export const Navigation = () => {
    const [currentPath, setCurrentPath] = useState(window.location.pathname);
    const [showFeedback, setShowFeedback] = useState(false);

    const {logout} = useUser();

    useEffect(() => {
        const handlePathChange = () => {
            setCurrentPath(window.location.pathname);
        };

        window.addEventListener('popstate', handlePathChange);

        return () => {
            window.removeEventListener('popstate', handlePathChange);
        };
    }, []);

    return (
        <>
            <nav className={styles.navigation}>
                <div className={styles.navigation_list}>
                        <div className={styles.navigation_list_sec}>
                            <ul className={styles.navigation_list_sec_list}>
                                {navigationList.map((item) =>
                                    <Link
                                        to={`/${item.path}`}
                                        className={`${styles.navigation_list_sec_list_item} ${"/" + item.path === currentPath ? styles.active : ''}`}
                                        key={item.title}
                                        onClick={() => setCurrentPath("/" + item.path)}
                                    >
                                        {item.icon} {item.title}
                                    </Link>
                                )}
                            </ul>
                        </div>
                </div>

                <div className={styles.navigation_bottom}>
                    <button className={styles.navigation_bottom_button} onClick={() => setShowFeedback(!showFeedback)}><VscFeedback/> Zpětná vazba</button>
                    <button className={styles.navigation_bottom_button} onClick={() => logout()}><IoIosLogOut/> Odhlásit se</button>
                </div>

            </nav>
            {showFeedback && <Modal title={"Zpětná vazba"} description={"Podejte nam zpetnou vazbu!"} onClose={() => setShowFeedback(false)}><FeedBack onClose={() => setShowFeedback(false)}/></Modal>}
        </>
    );
};
