import * as React from 'react';
import { createContext, useContext, useState } from 'react';
import {Loading} from "../components/loading/Loading";

interface LoadingContextType {
    loading: boolean;
    startGlobalLoading: () => void;
    stopGlobalLoading: () => void;
}

const LoadingContext = createContext<LoadingContextType | undefined>(undefined);

export const useLoading = (): LoadingContextType => {
    const context = useContext(LoadingContext);
    if (!context) {
        throw new Error('useLoading must be used within a LoadingProvider');
    }
    return context;
};

export const LoadingProvider: React.FC<{ children: React.ReactNode }> = ({ children } : { children: React.ReactNode }) => {
    const [loading, setLoading] = useState(false);
    const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);

    const setLoadingState = (isLoading: boolean) => {
        setLoading(isLoading);
    };

    const startLoadingTimer = () => {
        const timer = setTimeout(() => {
            setLoadingState(false);
        }, 10000);

        setTimerId(timer);
    };

    const startGlobalLoading = () => {
        setLoadingState(true);
        startLoadingTimer();
    };

    const stopGlobalLoading = () => {
        setLoadingState(false);
        if (timerId) {
            clearTimeout(timerId);
            setTimerId(null);
        }
    };

    const contextValue: LoadingContextType = { loading, startGlobalLoading, stopGlobalLoading };

    return (
        <LoadingContext.Provider value={contextValue}>
            {loading ? <Loading /> : null}
            {children}
        </LoadingContext.Provider>
    );
};

export const useGlobalLoading = (): LoadingContextType => {
    const { startGlobalLoading, stopGlobalLoading } = useLoading();
    return { loading: false, startGlobalLoading, stopGlobalLoading };
};
