import axios, { AxiosInstance } from "axios";


export const IP: string = process.env.REACT_APP_SERVER_IP || 'http://localhost:3000';

export const api: AxiosInstance = axios.create({

    baseURL: IP,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
    timeoutErrorMessage: 'Request timed out',

});
