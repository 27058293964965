import React, { useState, useRef, useEffect } from "react";
import styles from './dashboardpage.module.scss';

import {useUser} from "../../../utils/UserProvider";

export const DashboardPage: React.FC = () => {

    const user = useUser();

    return (
        <div className={styles.dashboard_content}>
            <h1>{user.user?.name}</h1>
        </div>
    );
};
