import React, { useState, useEffect } from 'react';
import Typing from 'react-typing-effect';
import ReactMarkdown from 'react-markdown';
import styles from './message.module.scss';
import { MessageAuthorType } from "../../../../../enum/MessageAuthorType";
import {MessageType} from "../../../../../types/MessageType";
import moment from "moment";

const Message = ({ msgData, isNew, scrollDown }: { msgData: MessageType, isNew: boolean, scrollDown: () => void }) => {
    const [markdownContent, setMarkdownContent] = useState('');

    useEffect(() => {
        const typingEffect = () => {
            let index = 0;
            const timer = setInterval(() => {
                setMarkdownContent(msgData.content.slice(0, index + 1));
                index += 1;
                if (index >= msgData.content.length) {
                    clearInterval(timer);
                }
                scrollDown();
            }, 10); //speed

            return () => clearInterval(timer);
        };

        if(msgData.sender === MessageAuthorType.ASSISTANT && isNew) {
            typingEffect();
        }
    }, [msgData.content]);

    return (
        <div className={`${styles.message} ${msgData.sender === MessageAuthorType.USER ? styles.user : styles.assistant}`} data-time={moment(msgData.created_at).format("D. M. YYYY HH:mm")}>
            <div className={styles.message_profile}>
            </div>
            <div className={styles.message_content}>
                <ReactMarkdown className={styles.message_content_text_markdown}>
                    {markdownContent || msgData.content}
                </ReactMarkdown>
            </div>
        </div>
    );
}

export default Message;
