import styles from './chatpage.module.scss';
import Message from "./components/message/Message";

import { FaArrowUp } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import { api } from "../../../api/API";
import { useLoading } from "../../../utils/LoadingProvider";
import { ThreadType } from "../../../types/ThreadType";

import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { MdOutlineAddToPhotos } from "react-icons/md";
import { MessageType } from "../../../types/MessageType";
import { MessageAuthorType } from "../../../enum/MessageAuthorType";

import { LuPenLine } from "react-icons/lu";

export const ChatPage = () => {
    const [threadsList, setThreadsList] = useState<ThreadType[]>();
    const [activeThread, setActiveThread] = useState<ThreadType>();

    const [messages, setMessages] = useState<MessageType[]>([]);
    const [typingMessage, setTypingMessage] = useState('');

    const [assistantTyping, setAssistantTyping] = useState(false);
    const messagesEndRef = useRef<null | HTMLDivElement>(null);

    const { startGlobalLoading, stopGlobalLoading } = useLoading();

    const handleSendMessage = async () => {
        if (typingMessage) {
            const newUserMessage = {
                sender: MessageAuthorType.USER,
                content: typingMessage,
                created_at: new Date(),
                updated_at: new Date(),
                thread_id: activeThread!.id,
                id: 0
            };

            setMessages(prevMessages => [...prevMessages, newUserMessage]);

            const userMessageIndex = messages.length;

            setTypingMessage('');
            scrollToBottom();

            try {
                setAssistantTyping(true);
                const messageResponse = await api.post(`/threads/${activeThread?.id}/messages`, { message: typingMessage });

                setMessages(prevMessages =>
                    prevMessages.map((msg, index) =>
                        index === userMessageIndex
                            ? {
                                ...msg,
                                id: messageResponse.data.user_message.id,
                                created_at: new Date(messageResponse.data.user_message.created_at),
                                updated_at: new Date(messageResponse.data.user_message.updated_at)
                            }
                            : msg
                    )
                );

                const assistantMessage = {
                    sender: MessageAuthorType.ASSISTANT,
                    content: messageResponse.data.assistant_response,
                    created_at: new Date(messageResponse.data.user_message.created_at),
                    updated_at: new Date(messageResponse.data.user_message.updated_at),
                    thread_id: messageResponse.data.user_message.thread_id,
                    id: messageResponse.data.user_message.id,
                    is_new: true
                };

                setMessages(prevMessages => [...prevMessages, assistantMessage]);
                setAssistantTyping(false);
                scrollToBottom();
            } catch (error) {
                console.error("Error sending message:", error);
            }
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSendMessage();
        }
    };

    const createThread = async () => {
        startGlobalLoading();
        const newThread = await api.post('/threads', {
            title: `Chat ${threadsList ? threadsList[threadsList.length - 1].id + 1 : 1}`,
        });

        setThreadsList([...threadsList!, newThread.data]);
        setActiveThread(newThread.data);
        stopGlobalLoading();
    };

    const scrollToBottom = () => {
        if (messagesEndRef.current !== null) {
            messagesEndRef.current.scrollIntoView({ block: "end" });
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        const fetchThreads = async () => {
            try {
                startGlobalLoading();

                const threadsListFetch = await api.get('/threads/user');
                setThreadsList(threadsListFetch.data);

                if (threadsListFetch.data.length > 0) {
                    setActiveThread(threadsListFetch.data[0]);
                }

                console.log(threadsList);

            } catch (error) {
                console.error('Error creating thread:', error);
            } finally {
                stopGlobalLoading();
            }
        };

        fetchThreads();
    }, []);

    useEffect(() => {
        const fetchActiveThreadMessages = async () => {
            startGlobalLoading();
            if (activeThread) {
                try {
                    const activeThreadMessagesFetch = await api.get(`/threads/${activeThread!.id}/messages?offset=0&limit=20`);

                    const sortedMessages = activeThreadMessagesFetch.data.sort((a: MessageType, b: MessageType) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
                    setMessages(sortedMessages);
                } catch (error) {
                    console.error('Error fetching messages:', error);
                } finally {
                    stopGlobalLoading();
                }
            }
            stopGlobalLoading();
        }

        fetchActiveThreadMessages();
    }, [activeThread]);

    return (
        <div className={styles.chat_page}>

            <ul className={styles.chat_list}>
                {threadsList?.map((thread, index) => (
                    <li className={styles.chat_list_item} key={index} onClick={() => setActiveThread(thread)}><IoChatbubbleEllipsesOutline /> {thread.title}</li>
                ))}
                <button className={styles.chat_list_add} onClick={createThread}><MdOutlineAddToPhotos /> Přidat chat</button>
            </ul>

            <div className={styles.chat_content}>
                <div className={styles.messages_content}>
                    {messages?.map((msg, index) => (
                        <Message key={index} msgData={msg} isNew={msg.is_new || false} scrollDown={scrollToBottom}/>
                    ))}
                    {assistantTyping && <p className={styles.assistant_typing}><LuPenLine /> Assistant is typing...</p>}
                    <div ref={messagesEndRef} />
                </div>

                <div className={styles.input_container}>
                    <input
                        className={styles.input_field}
                        type="text"
                        value={typingMessage}
                        onChange={(e) => setTypingMessage(e.target.value)}
                        onKeyDown={handleKeyDown} // Add this line
                        placeholder={assistantTyping ? "Assistant is typing..." : "Napiste zpravu..."}
                    />
                    <button
                        className={styles.input_submit}
                        onClick={handleSendMessage}
                        type="submit"
                        disabled={assistantTyping}
                    >
                        <FaArrowUp />
                    </button>
                </div>
            </div>
        </div>
    );
}
