import React, { useEffect, useState, useRef } from "react";
import { Outlet, Navigate } from 'react-router-dom';
import Cookies from "universal-cookie";
import { api } from "../api/API";
import { useNavigate } from "react-router-dom";
import { useUser } from "./UserProvider";
import { NotFound } from "../pages/notfound/NotFound";
import { Loading } from "../components/loading/Loading";

const cookies = new Cookies();

const PrivateRoutes = () => {
    const navigate = useNavigate();

    const verificationInterval = 30 * 60 * 1000;
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const { user, setUserData } = useUser();
    const hasVerifiedToken = useRef(false);

    useEffect(() => {
        const verifyToken = async () => {
            try {
                const accessToken = cookies.get("accessToken");
                const refreshToken = cookies.get("refreshToken");

                if (accessToken || refreshToken) {
                    api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
                    const userVerify = await api.post('/verify-token');

                    if (userVerify.data.success) {
                        if (!user) {
                            const userData = await api.get('/user');
                            if(userData.data.success){
                                console.log(userData.data.data);
                                setUserData(userData.data.data);
                            } else {
                                setIsAuthenticated(false);
                                cookies.remove("accessToken");
                                cookies.remove("refreshToken");
                            }
                        }
                        setIsAuthenticated(true);
                    } else {
                        setIsAuthenticated(false);
                        cookies.remove("accessToken");
                        cookies.remove("refreshToken");
                    }
                } else {
                    setIsAuthenticated(false);
                    cookies.remove("accessToken");
                    cookies.remove("refreshToken");
                }
            } catch (error) {
                await handleTokenError(error);
            } finally {
                hasVerifiedToken.current = true;
            }
        };

        const refreshToken = async () => {
            try {
                const refreshToken = cookies.get("refreshToken");
                if (refreshToken) {
                    const response = await api.post('/refresh-token', { refresh_token: refreshToken });

                    if (response.data.success) {
                        const newAccessToken = response.data.access_token;
                        cookies.set("accessToken", newAccessToken, { path: '/', secure: true, sameSite: 'strict', expires: new Date(Date.now() + 60 * 60 * 1000)});
                        api.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
                        return true;
                    } else {
                        return false;
                    }
                }
            } catch (error) {
                console.error("Failed to refresh token", error);
                return false;
            }
            return false;
        };

        const handleTokenError = async (error: any) => {
            if (error.response && error.response.status === 401) {
                const refreshed = await refreshToken();
                if (refreshed) {
                    hasVerifiedToken.current = false;
                } else {
                    handleTokenFailure();
                }
            } else {
                handleTokenFailure();
            }
        };

        const handleTokenFailure = () => {
            navigate('/', { replace: true });
            cookies.remove("accessToken");
            cookies.remove("refreshToken");
        };

        if (!hasVerifiedToken.current) {
            verifyToken();
        }

        const tokenRefreshInterval = setInterval(() => {
            refreshToken();
        }, verificationInterval);

        return () => {
            clearInterval(tokenRefreshInterval);
        };

    }, [user, navigate]);

    return (
        <>
            {cookies.get("accessToken") ? (isAuthenticated ? <Outlet /> : <Loading />) : <NotFound />}
        </>
    );
}

export default PrivateRoutes;
