import { IoMdClose } from "react-icons/io";
import styles from './modal.module.scss'
import React, {useEffect} from "react";
export const Modal = ({children, title, description, onClose}: {children: React.ReactNode, title: string, description: string, onClose: () => void}) => {

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'unset';
        }
    }, [])
    return (
        <div className={styles.modal }>
            <div className={styles.modal_content}>
                <div className={styles.controls}>
                    <button onClick={onClose} className={styles.close}><IoMdClose/></button>
                </div>
                <ModalHead title={title} description={description}/>
                {children}
            </div>
        </div>
    )
}

export const ModalHead = ({title, description} : {title: string, description: string}) => {
    return (
        <div className={styles.modal_title}>
            <h1>{title}</h1>
            <p>{description}</p>
        </div>
    )
}