import styles from "./profileeditcategory.module.scss";

import { BiMailSend } from "react-icons/bi";
import { GoUnverified } from "react-icons/go";
import { GoVerified } from "react-icons/go";
import {UserType} from "../../../../../types/UserType";
export const ProfileEditCategory = ({user} : {user: UserType}) => {
    return (
        <div className={styles.profile_category}>
            <div className={styles.pec_content}>
                <div className={styles.pec_content_main}>
                    <h2 className={styles.pec_content_main_name}>{user.email || "Načítání..."}</h2>
                    <p className={styles.pec_content_main_avataredit}>Změnit profilovou fotku</p>
                </div>
            </div>

            <form className={styles.profile_form}>
                <div className={styles.profile_form_sec}>
                    <div className={styles.input_container}>
                        <label className={styles.profile_form_label} htmlFor="first_name">Jméno</label>
                        <input className={styles.profile_form_input} id="first_name" name="first_name"
                               placeholder="Jméno"/>
                    </div>
                    <div className={styles.input_container}>
                        <label className={styles.profile_form_label} htmlFor="last_name">Příjmení</label>
                        <input className={styles.profile_form_input} id="last_name" name="last_name"
                               placeholder="Příjmení"/>
                    </div>
                </div>
                <div className={styles.profile_form_sec}>
                    <div className={styles.input_container}>
                        <label className={styles.profile_form_label} htmlFor="email">Email</label>
                        <input className={styles.profile_form_input} id="email" name="email" type="email"
                               placeholder="Email"/>
                        <div className={styles.profile_mail_verified}>
                            <GoUnverified/><p className={styles.profile_mail_verified_text}>Email není ověřen</p>
                            <button className={styles.profile_mail_verified_button}><BiMailSend/>Odeslat kód</button>
                        </div>
                    </div>
                    <div className={styles.input_container}>
                        <label className={styles.profile_form_label} htmlFor="tel">Telefonní číslo</label>
                        <input className={styles.profile_form_input} id="tel" name="tel" type="tel"
                               placeholder="Telefonní číslo"/>

                    </div>
                </div>
                    <button className={styles.profile_form_submit} type="submit">Uložit změny</button>
            </form>

        </div>
)
}