// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboardpage_chat_button__mwXzG {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #f5f5f5;
  font-size: 25px;
  border: 0;
  margin: 25px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/dashboard/dashboardpage.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,SAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,SAAA;EACA,YAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,yCAAA;AACF","sourcesContent":[".chat_button{\n  position: fixed;\n  bottom: 0;\n  right: 0;\n  width: 60px;\n  height: 60px;\n  border-radius: 50%;\n  background: #f5f5f5;\n  font-size: 25px;\n  border: 0;\n  margin: 25px;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chat_button": `dashboardpage_chat_button__mwXzG`
};
export default ___CSS_LOADER_EXPORT___;
