// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.cdnfonts.com/css/roboto);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
  padding: 0;
  top: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
  transition: 0.5s;
}

.dashboardlayout_dashboard_layout__6ZUzA {
  display: flex;
}
.dashboardlayout_dashboard_layout__6ZUzA .dashboardlayout_dashboard_layout_content__h0aUS {
  width: calc(100% - 300px);
  margin-top: 75px;
  margin-left: 300px;
  height: calc(100vh - 75px);
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/__global.scss","webpack://./src/layouts/dashboard_layout/dashboardlayout.module.scss"],"names":[],"mappings":"AAsBA;EACE,sBAAA;EACA,UAAA;EACA,MAAA;EACA,SAAA;EACA,iCAAA;EACA,gBAAA;ACpBF;;AANA;EACI,aAAA;AASJ;AAPI;EACI,yBAAA;EACA,gBDUQ;ECTR,kBDUW;ECTX,0BAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AASR","sourcesContent":["@import url('https://fonts.cdnfonts.com/css/roboto');\n\n$main-yellow-1: #F6D100;\n$main-yellow-2: #F8D92B;\n$main-yellow-3: #988519;\n$main-yellow-4: #FDF7D4;\n\n$main-grey-1: #6b6b6b;\n$main-grey-2: #D6D6D6;\n$main-grey-3: #e8e8e8;\n$box-padding: 30px;\n\n$box-border-radius-1: 20px;\n\n$box-margin-1: 50px;\n$box-margin-2: 25px;\n\n$header-height: 75px;\n$navigation-width: 300px;\n\n$box-shadow-1: 0 0 5px rgba(0, 0, 0, 0.1);\n\n*{\n  box-sizing: border-box;\n  padding: 0;\n  top: 0;\n  margin: 0;\n  font-family: 'Roboto', sans-serif;\n  transition: 0.5s;\n}\n\nbody{\n}","@import \"../../_global\";\n\n.dashboard_layout{\n    display: flex;\n\n    .dashboard_layout_content{\n        width: calc(100% - $navigation-width);\n        margin-top: $header-height;\n        margin-left: $navigation-width;\n        height: calc(100vh - $header-height);\n        padding: $box-padding;\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboard_layout": `dashboardlayout_dashboard_layout__6ZUzA`,
	"dashboard_layout_content": `dashboardlayout_dashboard_layout_content__h0aUS`
};
export default ___CSS_LOADER_EXPORT___;
