import styles from "./profilepage.module.scss";
import {useState} from "react";
import {ProfileEditCategory} from "./categories/profileeditcategory/ProfileEditCategory";

import {useUser} from "../../../utils/UserProvider";
import {UserType} from "../../../types/UserType";

export const ProfilePage = () =>{
    const [activeCategory, setActiveCategory] = useState(0);

    const user: UserType = useUser().user!;

    const categories = [
        {
            id: 0,
            title: "Úprava profilu",
            content: <ProfileEditCategory user={user}/>
        },
        {
            id: 1,
            title: "Doplňující informace",
            content: <ProfileEditCategory  user={user}/>
        },
        {
            id: 2,
            title: "Členství",
            content: <ProfileEditCategory user={user}/>
        },
        {
            id: 3,
            title: "Nastavení",
            content: <ProfileEditCategory user={user}/>
        },
    ];

    const links = [
        {
            id: 0,
            title: "Úprava profilu",
            path: "",
        },
        {
            id: 1,
            title: "Doplňující informace",
            path: "",
        },
        {
            id: 2,
            title: "Členství",
            path: "",
        },
        {
            id: 3,
            title: "Nastavení",
            path: "",
        },
    ]
    return (
        <div className={styles.profile_page}>
            <nav className={styles.profile_nav}>
                <ul className={styles.profile_nav_list}>
                    {links.map((link) => (
                        <li className={`${styles.profile_nav_list_item} ${activeCategory === link.id ? styles.active : ''}`} key={link.title} onClick={() => setActiveCategory(link.id)}>{link.title}</li>
                    ))}
                </ul>
            </nav>

            <div className={styles.profile_content}>
                <h1 className={styles.profile_content_title}>{categories[activeCategory]?.title}</h1>

                {categories[activeCategory]?.content}
            </div>
        </div>
    )
}