import {Outlet} from "react-router-dom";
import {Navigation} from "../../components/navigation/Navigation";

import styles from './dashboardlayout.module.scss';
import {Header} from "../../components/header/Header";
import {useGlobalLoading} from "../../utils/LoadingProvider";
import {Loading} from "../../components/loading/Loading";
const DashboardLayout = () => {

    const {loading} = useGlobalLoading();
    return (
        <div className={styles.dashboard_layout}>
            <Header/>
            <Navigation/>
            <div className={styles.dashboard_layout_content}>
                {loading ? <p><Loading/></p> : <Outlet/>}
            </div>
        </div>
    )
}

export default DashboardLayout