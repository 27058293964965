import styles from './feedback.module.scss';
import React, { useEffect, useRef } from 'react';

export const FeedBack = ({ onClose }: { onClose: () => void }) => {

    return (
               <div className={styles.feedback} >
                        <div className={styles.form_sec}>
                            <div className={styles.form_group}>
                                <label htmlFor="name">Jméno:</label>
                                <input type="text" id="name" name="name" required />
                            </div>
                            <div className={styles.form_group}>
                                <label htmlFor="email">E-mail:</label>
                                <input type="email" id="email" name="email" required />
                            </div>
                        </div>
                        <div className={styles.form_group}>
                            <label htmlFor="feedback">Zpětná vazba:</label>
                            <textarea id="feedback" name="feedback" required></textarea>
                        </div>

                        <button type="submit" className={styles.submit}>Odeslat</button>
               </div>
    );
};
